

import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { store } from '../../App';
import { GiWireframeGlobe } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoHome } from "react-icons/io5";

function BasicExample() {
  const [menus, setMenus, isOpen, setIsOpen, navbarclose, setNavbarClose] = useContext(store);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false); // For collapse functionality

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleSelect = () => {
    setExpanded(false); // Close navbar on selection
  };

  return (
    <>
      <Container fluid className="header">
        <Row>
          <Col lg={10} md={8} sm={10} xs={12} className="email-col">
            <Row>
              <Col lg={9} md={5} sm={6} xs={12} style={{ paddingRight: '0px' }} className="info-mail text-end">
                <p style={{ fontSize: '14px', marginBottom: "0px" }} className="info-header-email">
                  <MdOutlineMailOutline /> Info.oman@techno-elevators.com
                </p>
              </Col>
              <Col lg={3} md={6} sm={6} xs={12} className="info-helpline text-end">
                <p style={{ fontSize: '14px', marginBottom: "0px" }} className="helpline-num-header">
                  <BiSolidPhoneCall /> Help Line : +968 22527900
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={2} md={4} sm={2} className="icon-col">
            <div>
              <FaFacebookF />
              <FaLinkedinIn />
              <FaYoutube />
              <FaInstagram />
            </div>
          </Col>
        </Row>
      </Container>
      <Navbar expand="lg" className={navbarclose} sticky="top" expanded={expanded}>
        <Navbar.Brand as={Link} to="/">
          <img src="/Images/logo/techno-logo.png" width={"100%"} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menus && menus.items.map((item, index) => {
              return item.submenu ? (
                <NavDropdown
                  title={item.label}
                  id={`basic-nav-dropdown-${index}`}
                  renderMenuOnMount={true}
                  show={open}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                >
                  {item.submenu.map((menu) => (
                    <NavDropdown.Item
                      as={Link}
                      to={menu.url}
                      style={{ fontSize: '14px' }}
                      onClick={() => {
                        setOpen(false);
                        handleSelect();
                      }}
                    >
                      {menu.label}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : item.label === "Home" ? (
                <Nav.Link key={index} as={Link} to={item.url} onClick={handleSelect}>
                  <IoHome />
                </Nav.Link>
              ) : (
                <Nav.Link key={index} as={Link} to={item.url} onClick={handleSelect}>
                  {item.label}
                </Nav.Link>
              );
            })}
          </Nav>
          <Nav className="lang-nav">
            <Nav.Link id="basic-nav-globe" style={{ fontSize: '14px' }}>
              <GiWireframeGlobe />
            </Nav.Link>
            <NavDropdown title="ENGLISH" id="basic-nav-dropdown-lang">
              <NavDropdown.Item href="#action/3.1" style={{ fontSize: '14px' }}>
                ARABIC
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default BasicExample;
