import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  return (
    <>



      <div className="footer_section layout_padding" style={{ display: 'flex', justifyContent: 'center' }}>
        <Container>
          {/* <div className="address_main mt-5">
            <div className="address_text"><a href="#" style={{textDecoration:"none",marginLeft:'6%'}}><img src="https://themewagon.github.io/Heado/images/map-icon.png" alt="Map Icon" /><span className="padding_left_15">PO Box 253, Muscat-188, Sultanate Of Oman</span></a></div>
            <div className="address_text"><a href="#" style={{textDecoration:"none"}}><img src="https://themewagon.github.io/Heado/images/call-icon.png" alt="Call Icon" /><span className="padding_left_15">+00000000</span></a></div>
            <div className="address_text"><a href="#" style={{textDecoration:"none"}}><img src="https://themewagon.github.io/Heado/images/mail-icon.png" alt="Mail Icon" /><span className="padding_left_15">Info.oman@techno-elevators.com</span></a></div>
          </div> */}
          <div className="footer_section_2">
            <Row>
              <Col lg={3} sm={6}>
                <h6 className="link_text">Useful link</h6>
                <div className="footer_menu social_icon">

                  <p className='footer_text_2'><a href="/">Home</a></p>
                  <p className='footer_text_2'><a href="/about">About</a></p>
                  <p className='footer_text_2'><a href="/contact">Contact Us</a></p>

                </div>
              </Col>
              <Col lg={3} sm={6} className='footer-links'>
                <h6 className="link_text">SOLUTIONS</h6>
                <p className="footer_text_2" style={{ marginBottom: '0' }}><a href='/elevators' style={{ color: "black" }}> Products</a></p>
                <p className="footer_text_2" style={{ marginBottom: '0' }}><a href='/services' style={{ color: "black" }}> Service</a></p>
                <p className="footer_text_2" style={{ marginBottom: '0' }}><a href='/projects' style={{ color: "black" }}> Projects</a></p>
              </Col>

              <Col lg={3} sm={6}>
                <h6 className="link_text">social media</h6>

                <div className="social_icon">

                  <a href="#" style={{ paddingRight: '8px' }}><img src="https://themewagon.github.io/Heado/images/fb-icon.png" alt="Facebook Icon" /></a>
                  {/* <a href="#" style={{paddingRight:'8px'}}><img src="https://themewagon.github.io/Heado/images/twitter-icon.png" alt="Twitter Icon"/></a> */}
                  <a href="#" style={{ paddingRight: '8px' }}><img src="https://themewagon.github.io/Heado/images/linkedin-icon.png" alt="LinkedIn Icon" /></a>
                  <a href="#" ><img src="https://themewagon.github.io/Heado/images/youtub-icon.png" alt="YouTube Icon" /></a>

                </div>
              </Col>
              <Col lg={3}>
                <h6 className="link_text">Get In Touch</h6>
                <div className="social_icon">
                  <p className="footer_text_1" >
                    PO Box 253, Muscat-188,<br></br>
                    Sultanate Of Oman<br></br>
                    <p class="footer-p-tag">info.oman@techno-elevators.com</p>
                    <p class="footer-p-tag">Landline: +968 22527900</p>
                    <p class="footer-p-tag">Sales: +968 91393423</p>
                  </p>
                </div>
              </Col>

            </Row>
          </div>
        </Container >
      </div >

    </>
  )
}

export default Footer
